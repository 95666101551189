import React, { useState } from 'react';
import { Table, Button,Popover, Input } from 'antd';
import moment from 'moment';
import PopOverAttentionBtn from './PopOverAttention';
import NotesPop from './NotesPop'
import DataTable from '../../../components/data-display/table/DataTable';
import { FetchLocalData } from '../../../utils/helper';

const List = (props) => {
    const handleActions = (type,ticketID,remark) =>{
        
        props.handleActions(type,ticketID,remark)
    }
    const [popUpStatus,setPopUpStatus] = useState(false)

    const data = [];
    props.list &&
        props.list.map (item => {
      
        data.push ({
            key: item?.asset_details?.equipment_no,
            Equipment: item?.asset_details?.equipment_no,
            equipment_type:FetchLocalData(item.asset_details?.equipment_type_details?.parent_id,item?.asset_txns_details?.asset_type_details?.param_value),
            equipment_owner: item?.asset_details?.owner??'NA',
            Status: item?.ticket_status?.param_description,
            remark: item.remark?<NotesPop data={item.remark}/>:"NA",
            check_in: moment(item.created_at).format('MM/DD/YYYY'),
            last_tkt: item?.asset_txns_details?.last_known??'NA',
            truck_no:item?.asset_txns_details?.truck_number??"NA",
            Jobsite: item?.asset_txns_details?.job_site??'Josite',
            user:item?.asset_txns_details?.yard_details?.name??'NA',
            ticket_ref_id:item?.ticket_ref_id??'-',
            action: <>
            <PopOverAttentionBtn id={item?.ticket_id} SubmitRemarkWithRejection={(type,id,remark) => handleActions(type,id,remark)} title={'Approve'} type={'approve'}></PopOverAttentionBtn>
             <PopOverAttentionBtn id={item?.ticket_id} SubmitRemarkWithRejection={(type,id,remark) => handleActions(type,id,remark)} title={'Reject'} type={'reject'}></PopOverAttentionBtn> </>
           
        });
    })
    return(
        <div>
            {/* <Table scroll={{x: true}} loading={props.loading}  columns={props.columns} dataSource={data} /> */}
 <DataTable 
        loader={props.loading}
        header={props.tableColumns}
        data={data}
        rowKey="id" />
        </div>
    )
}

export default List;